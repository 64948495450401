import { FC, memo } from 'react';

import styles from './IkukoSectionTitleWrapper.module.scss';

const IkukoSectionTitleWrapper: FC = memo(({ children }) => {
  return (
    <h2 className={styles.searchTitle}>
      <div className={styles.ikuko_icon}>
        <img src="/images/iqfu/public/search_companies_stepper/ikuko_search.svg" alt="" />
      </div>
      <div className={styles.title_text}>{children}</div>
    </h2>
  );
});

IkukoSectionTitleWrapper.displayName = 'IkukoSectionTitleWrapper';

export default IkukoSectionTitleWrapper;
