import { FC, memo, useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { useRecoilState } from 'recoil';

import IkukoSectionTitleWrapper from '@components_iqfu/public/ikuko_section_title_wrapper/IkukoSectionTitleWrapper';
import StepperCityCodeSelect from '@components_iqfu/public/stepper_city_code_select/StepperCityCodeSelect';

import {
  searchCompanyAreaPrefectureInputVal,
  searchCompanyAreaCityInputVal,
} from '@recoil_iqfu/atoms/public/searchCompanyArea';

import styles from './SearchLocalCompanies.module.scss';

type Props = {
  pageLabel: string;
  defaultPrefecture?: string;
  defaultCity?: string;
  location: string;
  pageQueryType?: string;
  queryTypeString?: string;
};

const SearchLocalCompanies: FC<Props> = memo(
  ({
    pageLabel,
    defaultPrefecture,
    defaultCity,
    location,
    pageQueryType = '',
    queryTypeString = '売却実績が豊富な',
  }) => {
    const router = useRouter();
    const [prefectureInputVal, setPrefectureInputVal] = useRecoilState(searchCompanyAreaPrefectureInputVal);
    const [cityInputVal, setCityInputVal] = useRecoilState(searchCompanyAreaCityInputVal);

    const [disabledSearch, setDisabledSearch] = useState(true);
    const [targetPath, setTargetPath] = useState('');

    useEffect(() => {
      if (!router.isReady) return;

      const currentPath = router.asPath;

      const targetPath = prefectureInputVal
        ? cityInputVal
          ? `/companies/cities/${cityInputVal}/${pageQueryType}`
          : `/companies/prefectures/${prefectureInputVal}/${pageQueryType}`
        : null;

      if (!targetPath) {
        setDisabledSearch(true);
        return;
      } else {
        setDisabledSearch(false);
        setTargetPath(targetPath);
      }

      //都道府県も市区町村も選択されていない場合はdisable
      if (prefectureInputVal === '' && cityInputVal === '') {
        setDisabledSearch(true);
        return;
      }
      //都道府県にいる場合、現在と同じ都道府県で市区町村がセットされていない場合はdisable
      if (currentPath.includes('companies/prefectures/')) {
        const currentPrefecture = router.query.prefecture_code;
        if (currentPrefecture === prefectureInputVal && !cityInputVal) {
          setDisabledSearch(true);
          return;
        }
      }
      //市区町村にいる場合、現在と同じ市区町村にいる場合はdisable
      if (currentPath.includes('companies/cities/')) {
        const currentCity = router.query.city_code;
        if (currentCity === cityInputVal) {
          setDisabledSearch(true);
          return;
        }
      }
      setDisabledSearch(false);
    }, [router, prefectureInputVal, cityInputVal]);

    const displayQueryType = queryTypeString === '売却に強くて実績が豊富な' ? '売却実績が豊富な' : queryTypeString;

    return (
      <div>
        <div className={styles.search_companies_sectionTitle_container}>
          <IkukoSectionTitleWrapper>
            <span className="title_text_strong">「地元」</span>で{displayQueryType}
            <br className={'sp_only'} />
            不動産屋さんランキングを見る
          </IkukoSectionTitleWrapper>
        </div>

        <div className={styles.searchSelectWrap}>
          <div className={styles.city_code_select}>
            <div className={styles.process_label}>
              <p>
                <span className={styles.step}>STEP1</span>
                <span>地域を選択</span>
              </p>
            </div>
            <StepperCityCodeSelect defaultPrefecture={defaultPrefecture} defaultCity={defaultCity} />
          </div>
          <div className={styles.arrow_section}>
            <img src={`/images/iqfu/public/top/search_category_arrow.svg`} className={styles.arrow_icon} alt="" />
          </div>

          <div className={styles.category_button}>
            <div className={styles.process_label}>
              <p>
                <span className={styles.step}>STEP2</span>
                <span>ボタンを押す</span>
              </p>
            </div>
            <div className={styles.button_container}>
              <Link
                className={`${styles.submit_button} ${disabledSearch ? styles.inactive : ''}`}
                href={targetPath}
                data-gtm-click={`SearchLocalCompanies_ranking_company_search_${location}`}
              >
                ランキングを見る
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

SearchLocalCompanies.displayName = 'SearchLocalCompanies';

export default SearchLocalCompanies;
