import { FC } from 'react';

import styles from './SearchCompaniesWrapper.module.scss';

type Props = {
  pageLabel?: string;
  hasShadow?: boolean;
};

const SearchCompaniesWrapper: FC<Props> = ({ pageLabel, hasShadow = false, children }) => {
  return (
    <div
      className={`${styles.searchBoxWrap} ${pageLabel === 'home' ? styles.top_page_searchBox : ''}  ${
        hasShadow ? styles.has_shadow : ''
      }`}
    >
      <div className={styles.background_image} />
      <div className={`${styles.searchBox} ${pageLabel === 'home' ? styles.home : ''}`}>{children}</div>
    </div>
  );
};

export default SearchCompaniesWrapper;
