import * as Sentry from '@sentry/browser';

import { getSaleCaseListQuery, getSaleCaseMapPinListQuery } from '@type-def_iqfu/public/getSaleCaseListQuery';
import { SaleCaseItem } from '@type-def_iqfu/public/SaleCaseItem';

const queryString = require('query-string');

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

//getStaticProps内で使うのでhooksにしない
const getSaleCasesList = async (queryParams: getSaleCaseListQuery): Promise<{ sale_cases: SaleCaseItem[] }> => {
  const query = queryString.stringify(queryParams);
  const url = `${backendBasePath}/sale_cases?${query}`;

  const result = await fetch(url, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('通信に失敗しました', error);
      return error;
    });
  return result;
};

//getStaticProps内で使うのでhooksにしない
const getSaleCasesMapPinList = async (queryParams: getSaleCaseMapPinListQuery) => {
  if (!queryParams.company_id) return;
  const query = queryString.stringify(queryParams);
  const url = `${backendBasePath}/sale_case_map_pins?${query}`;

  const result = await fetch(url, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('通信に失敗しました', error);
      return error;
    });
  return result;
};

export { getSaleCasesList, getSaleCasesMapPinList };
