import { FC, memo } from 'react';

import Link from 'next/link';

import styles from './PricePageLinks.module.scss';

type Props = {
  pageLabel: string;
  areaInfo: { areaName: string; areaType: 'country' | 'prefecture' | 'city'; areaCode?: number };
};

const PricePageLinks: FC<Props> = memo(({ pageLabel, areaInfo }) => {
  const getTargetPath = (pageType: 'mansions' | 'lands' | 'houses') => {
    const linkCategory = pageType === 'mansions' ? 'mansions' : 'landhouses';
    let targetPath = '';

    if (areaInfo.areaType === 'country') {
      targetPath = `/${linkCategory}`;
    } else if (areaInfo.areaType === 'prefecture' && areaInfo.areaCode) {
      targetPath = `/${linkCategory}/prefectures/${areaInfo.areaCode}`;
    } else if (areaInfo.areaType === 'city' && areaInfo.areaCode) {
      targetPath = `/${linkCategory}/cities/${areaInfo.areaCode}`;
    }

    return targetPath;
  };

  return (
    <div>
      <div className={styles.search_companies_sectionTitle_container}>
        <h2 className={styles.searchTitle}>
          <img src={`/images/iqfu/public/top/glass_icon.svg`} className={styles.glass_icon} alt="" />
          <div>
            {areaInfo.areaName}
            <span className={areaInfo.areaType === 'city' ? styles.title_city_label : ''}>
              の売却相場・動向を調べる
            </span>
          </div>
        </h2>
      </div>

      <div className={styles.linkButtonsWrap}>
        <Link
          href={getTargetPath('lands')}
          prefetch={false}
          className={styles.searchSelect}
          data-gtm-click="PricePageLinks_land_market_price"
        >
          <span className={styles.icon_image}>
            <img src="/images/iqfu/public/price_page_links/land_icon.svg" alt="" />
          </span>
          <div className={`${styles.button_text}`}>
            土地
            <br className={'sp_only'} />
            の売却相場
          </div>
        </Link>

        <Link
          href={getTargetPath('houses')}
          prefetch={false}
          className={styles.searchSelect}
          data-gtm-click="PricePageLinks_house_market_price"
        >
          <span className={styles.icon_image}>
            <img src="/images/iqfu/public/price_page_links/house_icon.svg" alt="" />
          </span>
          <div className={`${styles.button_text}`}>
            戸建て
            <br className={'sp_only'} />
            の売却相場
          </div>
        </Link>

        <Link
          href={getTargetPath('mansions')}
          prefetch={false}
          className={styles.searchSelect}
          data-gtm-click="PricePageLinks_mansion_market_price"
        >
          <span className={styles.icon_image}>
            <img src="/images/iqfu/public/price_page_links/mansion_icon.svg" alt="" />
          </span>
          <div className={`${styles.button_text}`}>
            マンション
            <br className={'sp_only'} />
            の売却相場
          </div>
        </Link>
      </div>
    </div>
  );
});

PricePageLinks.displayName = 'PricePageLinks';

export default PricePageLinks;
